import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { useRegionChanged } from "@util/hooks";
import { BlocksContent, ContactForm } from "@global";
import { Container } from "@util/standard";
import {
  colorsRGB,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";

const ContactInfo = styled(Container)`
  flex-direction: row;
  margin: auto;
  height: 100%;
  align-items: center;
  background-color: ${colorsRGB.statement()};
`;

const Info = styled(Container)`
  width: 75%;
  margin: 141px auto 110px auto;
  justify-content: space-evenly;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    text-align: left;
    margin: 70px auto 40px auto;
  }
`;

const Content = styled(Container)`
  margin: 0 auto;
  width: 17%;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 24%;
    margin: auto auto 30px auto;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 41%;
  }
`;

interface Props extends PageProps {
  data: Query;
  pageContext: { iban: string };
}

export default function ContactPage({ data, pageContext }: Props) {
  const { nodes } = data.allSanityContact;
  const { seo, getInTouch, socialMediaIcon, contactInfo } = nodes[0];

  useRegionChanged("contact", pageContext.iban);

  return (
    <div>
      <SEO seoData={seo} slug="contact" />
      {getInTouch && socialMediaIcon && (
        <ContactForm info={getInTouch} media={socialMediaIcon} />
      )}
      <ContactInfo>
        <Info>
          {contactInfo &&
            contactInfo.map((content) => {
              if (content == null) {
                return null;
              }
              return (
                <Content key={content?._key}>
                  <BlocksContent blocks={content?._rawColumnContent} />
                </Content>
              );
            })}
        </Info>
      </ContactInfo>
    </div>
  );
}

export const query = graphql`
  query ContactPageQuery($iban: String) {
    allSanityContact(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        getInTouch {
          ...sanityGetInTouchBlock
        }
        socialMediaIcon {
          socialIconWhite {
            ...sanityImage
          }
          mediaIcon {
            ...sanityImageFullWidth
          }
        }
        contactInfo {
          _rawColumnContent
          _key
        }
      }
    }
  }
`;
